// AboutPage.js
import React from 'react';
import logo from '../asset/1.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-scroll";

function footer() {
    return (
        <>
            <footer id="newsletter">
                <div className="container">
                    <div className="section-heading footer-heading flex justify-center">
                        <div className="flex items-center">
                            <img
                                src={logo}
                                className="h-8 me-3"
                                alt="FlowBite Logo"
                            />
                            <h1 className="self-center text-2xl font-semibold whitespace-nowrap text-white">
                                ĐPS - Trung Tâm đào tạo bóng đá Đăng Phúc Sport
                            </h1>
                        </div>
                    </div>
                    <div className="flex flex-col sm:flex-row mx-auto">
                        <div className=" flex justify-center w-full sm:w-1/3 p-4">
                            <div className="footer-widget">
                                <h4 className='flex justify-center sm:justify-between'>LIÊN HỆ</h4>
                                <ul className='cursor-pointer'>
                                    <li>

                                        <p className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white cursor-pointer"
                                        ><FontAwesomeIcon icon={faEnvelope} /> dangphuc1401@gmail.com</p>
                                    </li>
                                    <li>

                                        <p className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white cursor-pointer"
                                        ><FontAwesomeIcon icon={faPhone} /> 033 977 5337</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className=" flex justify-center w-full sm:w-1/3 p-4">
                            <div className="footer-widget">
                                <h4>THEO DÕI</h4>
                                <ul className='cursor-pointer'>
                                    <li>
                                        <a
                                            href="https://www.facebook.com/dangphucsportfc"
                                            target='blank'
                                        >
                                            <p className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white cursor-pointer"
                                            >Facebook</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.youtube.com/@caulacbobongaangphucsportf6888?app=desktop"
                                            target='blank'
                                        >
                                            <p className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white cursor-pointer"
                                            >Youtube</p>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className=" flex justify-center w-full sm:w-1/3 p-4">
                            <div className="footer-widget">
                                <h4>About Us</h4>
                                <ul className='cursor-pointer'>
                                    <li>
                                        <Link activeClass="active" smooth spy to="gioithieu">
                                            <p
                                                className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white cursor-pointer"
                                                aria-current="page"
                                            >
                                                Giới thiệu
                                            </p>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link activeClass="active" smooth spy to="khoahoc">
                                            <p
                                                className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white cursor-pointer"
                                                aria-current="page"
                                            >
                                                Khóa học
                                            </p>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link activeClass="active" smooth spy to="lichhoc">
                                            <p
                                                className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white cursor-pointer"
                                                aria-current="page"
                                            >
                                                Lịch học
                                            </p>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link activeClass="active" smooth spy to="hinhanh">
                                            <p
                                                className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white cursor-pointer"
                                                aria-current="page"
                                            >
                                                Hình ảnh
                                            </p>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link activeClass="active" smooth spy to="danhgia">
                                            <p
                                                className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white cursor-pointer"
                                                aria-current="page"
                                            >
                                                Đánh giá
                                            </p>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link activeClass="active" smooth spy to="dangki">
                                            <p
                                                className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white cursor-pointer"
                                                aria-current="page"
                                            >
                                                Đăng kí
                                            </p>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>

    );
}

export default footer;
