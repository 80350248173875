import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import axios from 'axios';

interface Component {
  title: string;
  digit: string;
}

interface Comment {
  authorName: string;
  content: string;
  avatarSrc: string;
}

interface ApiDataItem {
  id: number;
  mainBannerHeader: string;
  mainBannerText: string;
  youtubeLink: string;
  introductionHeader: string;
  introductionContent: string;
  introductionImages: string[];
  components: Component[];
  comments: Comment[];
  created_at: string;
  updated_at: string;
}

interface ApiResponse {
  success: boolean;
  data: ApiDataItem[];
}

interface ApiContextProps {
  data: ApiDataItem[] | null;
}


const ApiContext = createContext<ApiContextProps | undefined>(undefined);

export const useApi = (): ApiContextProps => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error('useApi must be used within an ApiProvider');
  }
  return context;
};

interface ApiProviderProps {
  children: ReactNode;
}

export const ApiProvider: React.FC<ApiProviderProps> = ({ children }) => {
  const [data, setData] = useState<ApiDataItem[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get<ApiResponse>('https://admin.dangphucsport.vn/api/page', {
          headers: {
            Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9',
            'Content-Type': 'application/json',
          }
        });
        setData(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <ApiContext.Provider value={{ data }}>
      {children}
    </ApiContext.Provider>
  );
};
