/* eslint-disable jsx-a11y/iframe-has-title */
// AboutPage.js
import { useEffect, useState } from 'react';
import Hinh from "../asset/services-right-dec.png";
import icon1 from "../asset/services-left-dec.png";
import axios from 'axios';

import { Card } from 'antd';

import { Typography } from 'antd';
const { Meta } = Card;
const { Title } = Typography;

interface Schedule {
    day_of_week: string;
    start_time: string;
    end_time: string;
}

interface Location {
    id: string;
    location_name: string;
    address: string;
    map_link: string;
    schedules: Schedule[];
}

function LichHoc() {
    const [locations, setLocations] = useState<Location[]>([]);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://admin.dangphucsport.vn/api/locations');
                setLocations(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to fetch locations');
            }
        };

        fetchData();
    }, []);

    // console.log(locations);


    if (error) {
        return <div>{error}</div>;
    }

    return (
        <div id="services" className="our-services section sm:px-28" style={{ zIndex: "9" }}>
            <div className="services-right-dec ">
                <img src={Hinh} alt="" />
            </div>
            <div className="">
                <div className="services-left-dec">
                    <img src={icon1} alt="" />
                </div>
                <div className="flex justify-center">
                    <div className="section-heading">
                        <h2>
                            <em>LỊCH HỌC</em> BÓNG ĐÁ QUANH NĂM
                        </h2>
                    </div>
                </div>
            </div>
            <div className='flex justify-center'>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-5" style={{ overflow: 'hidden', textAlign: 'left' }}>
                    {locations.map((location) => (
                        <Card key={location.id} style={{ width: 300 }} className='bg-gray-200 shadow-md'>
                            <div style={{ height: '600px' }} className='flex flex-col justify-between'>
                                <Meta
                                    title={<Title level={4}><div className='mt-5 text-red-600'>{`CƠ SỞ ${location.id}: ${location.location_name}`}</div></Title>}
                                    description={
                                        <div className='flex flex-col text-black text-left text-base'>
                                            <span> <span className='font-bold'>- Địa chỉ:</span> {location.address}</span>
                                            <span className='font-bold'>- Lịch học:</span>
                                            {location.schedules.map((schedule, index) => (
                                                <span key={index}>✅ {`${schedule.day_of_week}: ${schedule.start_time} - ${schedule.end_time}`}</span>
                                            ))}
                                        </div>
                                    }
                                />
                                <iframe
                                    src={location.map_link}
                                    width={270}
                                    height={200}
                                    style={{ border: 0 }}
                                    className='mt-5 w-full'
                                    allowFullScreen
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                />
                            </div>
                        </Card>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default LichHoc;
