import React from 'react';
import Hinh from "../asset/portfolio-left-dec.png";
import Hinh2 from "../asset/tables-left-dec.png";
import Cardcommon from '../component/card';
import { useApi } from '../context/ApiContext';

function HinhAnh() {
    const { data } = useApi();
    if (!data || data.length === 0) {
      return <div>Loading...</div>;
    }
    const item = data[0];

    const cardData = item.comments.map((comment: any) => {
        // In giá trị avatarSrc ra console
        const avatarSrcUrl = `https://admin.dangphucsport.vn/images/comments/${comment.avatarSrc}`;
        // console.log('Avatar Source URL:', avatarSrcUrl);

        return {
            avatarSrc: avatarSrcUrl, 
            content: comment.content,      
            authorName: comment.authorName 
        };
    });

    return (
        <div id="services" className="our-services section">
            <div className="services-right-dec">
                <img src={Hinh} alt="Background" />
            </div>
            <div>
                <div className="services-left-dec">
                    <img src={Hinh2} alt="Background" />
                </div>
                <div className="flex justify-center">
                    <div className="section-heading">
                        <h2>
                            <em>CẢM NHẬN</em> TỪ PHỤ HUYNH VÀ HỌC VIÊN
                        </h2>
                    </div>
                </div>
            </div>
            <div className='px-5 sm:px-20 flex flex-col items-center md:flex-row md:justify-center md:gap-10'>
                {cardData.map((data: any, index: number) => (
                    <Cardcommon
                        key={index}
                        avatarSrc={data.avatarSrc} 
                        content={data.content}
                        authorName={data.authorName}
                    />
                ))}
            </div>
        </div>
    );
}

export default HinhAnh;
