import React from 'react';
import Hinh from "../asset/huan-luyen-vien.webp";
import icon1 from "../asset/service-icon-01.png";
import icon2 from "../asset/service-icon-02.png";
import icon3 from "../asset/service-icon-03.png";
import { useApi } from '../context/ApiContext';

function GioiThieu() {
  const { data } = useApi();
  if (!data || data.length === 0) {
    return <div>Loading...</div>;
  }
  const item = data[0];

  return (
    <div id="about" className="about-us section sm:px-28">
      <div className="sm:flex sm:flex-row">
        <div className="sm:w-1/2">
          <div><img src={`https://admin.dangphucsport.vn/images/${item.introductionImages}`} alt="Huấn luyện viên" /></div>
        </div>
        <div className="sm:w-1/2 mr-10" style={{ zIndex: "99" }}>
          <div className="item ml-10">
            <div dangerouslySetInnerHTML={{ __html: item.introductionHeader }} />
            <div dangerouslySetInnerHTML={{ __html: item.introductionContent }} />
          <div className="flex ml-10">
            <div className="lg:w-1/3 flex justify-center">
              <div className="fact-item">
                <div className="flex flex-col items-center">
                  <div className="icon">
                    <img src={icon1} alt="Học viên" />
                  </div>
                  <div className="count-digit">{item.components[0].digit}</div>
                  <div className="count-title">{item.components[0].title}</div>
                </div>
              </div>
            </div>
            <div className="lg:w-1/3 flex justify-center">
              <div className="fact-item">
                <div className="flex flex-col items-center">
                  <div className="icon">
                    <img src={icon2} alt="Cơ sở" />
                  </div>
                  <div className="count-digit">{item.components[1].digit}</div>
                  <div className="count-title">{item.components[1].title}</div>
                </div>
              </div>
            </div>
            <div className="lg:w-1/3 flex justify-center">
              <div className="fact-item">
                <div className="flex flex-col items-center">
                  <div className="icon">
                    <img src={icon3} alt="Giáo viên" />
                  </div>
                  <div className="count-digit">{item.components[2].digit}</div>
                  <div className="count-title">{item.components[2].title}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
export default GioiThieu;
